export default [
  {
    name: 'Gran Canaria (Norte)',
    postCode: '35011',
  },
  {
    name: 'Gran Canaria (Centro)',
    postCode: '35230',
  },
  {
    name: 'Gran Canaria (Sur)',
    postCode: '35100',
  },
  {
    name: 'Tenerife (Norte)',
    postCode: '38295',
  },
  {
    name: 'Tenerife (Sur)',
    postCode: '38639',
  },
  {
    name: 'La Palma',
    postCode: '38700',
  },
  {
    name: 'Fuerteventura',
    postCode: '35630',
  },
]
